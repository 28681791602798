// import logo from './logo.svg';
//import {  BrowserRouter as Router,  Route} from 'react-router-dom';
import Grido from '../Components/Grid';
// import { Link, animateScroll as scroll } from "react-scroll";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
// import { Grid, Paper, Avatar, TextField } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { IconButton } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import jean from "../Jean.jpg";
import franco from "../Franco.jpg";
import gabriel from "../Gabriel.jpg"
import bastian from "../Bastian.jpg"
import daniel from "../Daniel.jpg";
import camilo from "../Camilo.jpeg";
import person from "../person.jpg";
import EmailIcon from '@material-ui/icons/Email';
import YouTubeIcon from '@material-ui/icons/YouTube';
import NavBar2 from '../Components/Navbar/navbar2'


const useStyles = makeStyles((theme) => ({
    card: {
      maxWidth: 345,
      margin: 'auto'
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: "center", display: "flex" 
      },
    },
    avatar:{
      [theme.breakpoints.down('sm')]: {
        width:"5em", height:"5em"
      },
  
    },
    features:{
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function Equipo() {
    const classes = useStyles();
    return (
      <div >
          <NavBar2> </NavBar2>
        <header className="seccion" id="section23">        
            <Grid >
            <Grid  container justifyContent="center">
                <Grid item xs={12} >
                <Typography style={{marginBottom:50}} variant="h3">Conocenos un poco más</Typography>
                </Grid>
                <Grid  item sm={12} md={3}  style={{margin:"1em"}}>
                <Paper data-aos="zoom-in" elevation={3}  className={classes.paper}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Typography variant="h4">No Software No Life</Typography>
                    </Grid>
                    <Grid  item xs={12} >
                        <Typography variant="h5" > 
                        Somos un equipo de estudiantes de la universidad Técnica Federico Santa María con el 
                        propósito de generar más emprendimientos exitosos en Chile y en Latinoamérica.
                        </Typography>
                    </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid  item sm={12} md={3}  style={{margin:"1em"}}>
                <Paper data-aos="zoom-in" elevation={3}  className={classes.paper}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Typography variant="h4">Misión</Typography>
                    </Grid>
                    <Grid  item xs={12} >
                        <Typography variant="h5" > 
                        Ayudar a los emprendedores a alcanzar sus metas dando una retroalimentación 
                        sobre sus video-pitch y agilizando el proceso por el cual pueden postular a fondos
                        </Typography>
                    </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid  item sm={12} md={3}  style={{margin:"1em"}}>
                <Paper data-aos="zoom-in" elevation={3}  className={classes.paper}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Typography variant="h4">Visión</Typography>
                    </Grid>
                    <Grid  item xs={12} >
                        <Typography variant="h5" > 
                        Mejorar la percepción general de startups o empresas recién entrando en el mercado
                        </Typography>
                    </Grid>
                    </Grid>
                </Paper>
                </Grid>
            </Grid>
            
            </Grid>
        </header>

        <header className="seccion2" id="section3">
        <Typography style={{marginBottom:50}} variant="h3">Nuestro equipo</Typography>
            <Grid justifyContent = "center">
            <Grid data-aos="fade-in" container spacing={4} justifyContent = "center">
                <Grido foto={bastian} nombre="Bastián Chávez" cargo="Product Owner" cargo2="Backend Dev" link="https://www.linkedin.com/in/basuz3" />
                <Grido foto={daniel} nombre="Daniel Castro" cargo="Frontend Dev" cargo2="Analista QA" link="https://www.linkedin.com/in/danielcastroc" />
                <Grido foto={jean} nombre="Jean Aravena" cargo="Data Scientist" cargo2="AI Developer" link="https://www.linkedin.com/in/jean-aravena" />
                <Grido foto={franco} nombre="Franco Palma" cargo="Frontend Dev" cargo2="Marketing" link="https://www.linkedin.com/in/francopalmamuñoz" />
                <Grido foto={gabriel} nombre="Gabriel Vergara" cargo="Frontend Dev" cargo2="Analista Tester" link="https://www.linkedin.com/in/gabrielvergaradonoso/" />
                <Grido foto={camilo} nombre="Camilo Sánchez" cargo="Scrum Master" cargo2="Backend Dev" link="https://www.linkedin.com/in/camilosanchezb" />            
            </Grid>
            </Grid>
        </header>
      </div>
    );
  }
  
  export default Equipo;
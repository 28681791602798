import React from 'react';
//import './footer.css';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Container } from '@material-ui/core';
// import Link from '@material-ui/core/Link'
// import { Toolbar } from '@material-ui/core';
// import { AppBar } from '@material-ui/core';
// import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import logo from "./Carita.svg";
import logo2 from "./digevo_ventures.svg"
import logo3 from "./nsnl.svg"

const styles = makeStyles({
    box1: {
        textAlign: "left",
        ["@media (max-width:780px)"]: {
            textAlign: "center",
        }
    },
    box2: {
        ["@media (max-width:780px)"]: {
            paddingBottom: "1.5em",
        }
    },
    footer:{
        ["@media (max-width:780px)"]: {
            flexDirection: "column",
            alignItems: 'center',
        }
    }
})


function Footer(params) {
    const classes = styles();
    return(
        <div >
            <Box bgcolor="#F36432" color="white" style={{paddingTop:30/*marginTop:50*/}}>
                <Container maxwidth="lg">
                    <Grid container  className={classes.footer} spacing={5}>
                        <Grid xs={6} style={{}}>
                            <Box className={classes.box1} style={{paddingTop:"0.1em", paddingBottom:"0.1em"}} pt={{xs:5, sm:10}} pb={{xs:5, sm:0}}>
                                <a style ={{}}>
                                    <img src={logo3} alt="logo" width= "130em" height= "130em" />
                                </a>
                                <a >
                                    <img src={logo2} alt="logo" width= "130em" height= "130em" />
                                </a>
                            </Box>
                        </Grid>
                        <Grid xs={6}>
                            <Box className={classes.box2} textAlign="right" pt={{xs:5, sm:10}} pb={{xs:5, sm:0}}>
                                &copy;{new Date().getFullYear()} No Software No Life | All right reserved
                            </Box>
                        </Grid>
                    
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default Footer;
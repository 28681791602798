
import NavBar2 from '../Components/Navbar/navbar2'

function NotFound() {
    
    return (
      <div >
          <NavBar2> </NavBar2>
            
          
          <header className="seccion2" >
              
          <h1>404 - Not Found!</h1>
        </header>
      </div>
    );
  }
  
  export default NotFound;
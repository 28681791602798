import React from 'react'
import {Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import { Grid, Paper, Avatar} from '@material-ui/core';
// import { Grid, Paper, Avatar, TextField } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Button from '@material-ui/core/Button';

const styles= makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          justifyContent: "center", display: "flex" 
        },
    },
    avatar:{
        width:"8em",
        height:"8em",
        [theme.breakpoints.down('sm')]: {
          width:"5em", height:"5em"
        },
        ["@media (max-width:780px)"]: {
            width:"5em",
            height:"5em"
        }
    },
    bigSpace: {
        marginTop: "5rem"
    },
    littleSpace: {
        marginTop: "2.5rem",
    },
    grid: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
    },
    datos:{
        ["@media (max-width:780px)"]: {
          flexDirection: "column"
        }
    },
    grilla:{
        xs: "12",
        sm: "4",
        ["@media (max-width:780px)"]: {
            xs: "4",
            sm: "8",
            alignItems:"center",
            justifyContent: "center",
          }
    }
}))

function Grido(props) {
    const {foto, nombre, cargo, cargo2, link} = props;
    const classes = styles();
    return (
        <Grid item  classbane={classes.grilla} spacing={2}>
              <Paper  className={classes.paper}>
                <Avatar className={classes.avatar} style={{/* justifyContent: "center", display: "flex" */}} src={foto} />
                <div className={classes.datos}>
                  <Typography variant="h6">{nombre}</Typography>
                  <Typography variant="subtitle1">{cargo}</Typography>
                  <Typography variant='subtitle1'>{cargo2}</Typography>
                  <Button color="inherit" href={link} rel="noopener noreferrer" target="_blank">
                    <LinkedInIcon/> 
                  </Button>
                </div>
              </Paper>
        </Grid>
    )
}

export default Grido

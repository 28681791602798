// import logo from './logo.svg';
//import {  BrowserRouter as Router,  Route} from 'react-router-dom';
import Grido from '../Components/Grid';
// import { Link, animateScroll as scroll } from "react-scroll";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
// import { Grid, Paper, Avatar, TextField } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { IconButton } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import AssessmentIcon from '@material-ui/icons/Assessment';
/*import jean from "./Jean.jpg";
import franco from "./Franco.jpg";
mport gabriel from "./Gabriel.jpg"
import bastian from "../Bastian.jpg"
import person from "./person.jpg";*/
import EmailIcon from '@material-ui/icons/Email';
import YouTubeIcon from '@material-ui/icons/YouTube';
import NavBar from '../Components/Navbar/navbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hidden from '@mui/material/Hidden';
import iframe from 'react-iframe';

AOS.init();

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: 'auto'
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center", display: "flex" 
    },
  },
  avatar:{
    [theme.breakpoints.down('sm')]: {
      width:"5em", height:"5em"
    },

  },
  features:{
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  correo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: ".7em",
    },
  },
}));



function Home (){
    const classes = useStyles();
    return(
        <div>
            <NavBar> </NavBar>
            <header className="seccion" id="section1">      
              <Grid style={{marginLeft:450, marginRight:450}}>
                <Grid item  xs ={12}>
                <Typography data-aos="fade-down" style={{marginBottom:50}} variant="h3">¿Qué es Speaksy? </Typography>
                    <Typography style={{marginTop:20, marginBottom:20, color:"#6c6c6c"}}>
                    Speaksy nace como un apoyo para aquellas personas que quieren postular con su startup a un financiamiento,
                    está herramienta permite agilizar el proceso de obtención de variables para el análisis de viabilidad
                    del proyecto.
                    </Typography>        
                </Grid>
                <Grid data-aos="zoom-out-up" item  xs ={12}>
                        <iframe style={{width:"20em",height:"15em"}} src="https://www.youtube.com/embed/oaRKDFTsEjM"></iframe>      
                </Grid>
              </Grid>
            </header>

            <header className="seccion2" id="section2">        
              <Grid  container justifyContent="center">
                  <Grid item xs={12} >
                  <Typography style={{marginBottom:50}} variant="h3">Nuestro servicio</Typography>
                  </Grid>
                  <Grid item style={{marginLeft:"10%", marginRight:"10%"}}>
                        <Typography style={{marginTop:20, marginBottom:20, color:"#6c6c6c"}} variant="h5" > 
                        Entregamos una evaluación de tu presentación video Pitch tomando en consideración variables utilizadas 
                        para medir éxito de startups, con esta podrás ver la información expuesta por ti y el cómo lo expones, 
                        para finalmente alinear tus objetivos con lo que dice tu exposición.
                        </Typography>
                  </Grid>
                  <Grid  item sm={12} md={4}  style={{margin:"1em"}}>
                  <Paper data-aos="fade-in" elevation={3}  className={classes.paper}>
                      <ComputerIcon style={{height:"3em", width:"3em"}}/>
                      <Typography variant="h5" > 
                      Prepara el video-pitch de tu startup y súbelo a nuestra plataforma para que sea analizado

                      </Typography>
                  </Paper>
                  </Grid>
                  <Grid  item sm={12} md={4}  style={{margin:"1em"}}>
                  <Paper data-aos="fade-in" elevation={3}  className={classes.paper}>
                      <AssessmentIcon style={{height:"3em", width:"3em"}}/>
                      <Typography variant="h5" > 
                      Procesamos el video, rescatamos la mayor cantidad de variables útiles y generamos el reporte
                      </Typography>
                  </Paper>
                  </Grid>
              </Grid>
            </header>
        
        

          <header className="seccion" id="section4">
              <Grid >
              <Grid  container justifyContent="center">
                  <Grid item xs={12} >
                  <Typography style={{marginBottom:50, marginTop:50}} variant="h3">Contáctanos</Typography>
                  </Grid>
                  <Grid  item sm={12} md={6}  style={{margin:"1em"}}>
                  <Paper data-aos="fade-right" elevation={3}  className={classes.paper}>
                      <Grid container spacing={3}>
                      <Grid item xs={12} >
                          <Typography variant="h5">Queremos escuchar sobre ti</Typography>
                      </Grid>
                      <Grid  item xs={12} >
                          <IconButton href="mailto:speaksy.contacto@gmail.com" edge="start" className={classes.menuButton} color="inherit" >
                          <EmailIcon style={{color: "#F36432",height:"2em", width:"2em"}}/>
                          <a className={classes.correo}>speaksy.contacto@gmail.com </a>
                          </IconButton>
                      </Grid>
                      </Grid>
                  </Paper>
                  </Grid>
                  <Grid  item sm={12} md={6}  style={{margin:"1em"}}>
                  <Paper data-aos="fade-left" elevation={3}  className={classes.paper}>
                  <Typography style={{marginTop:20}} variant="h5">Síguenos en nuestras redes sociales</Typography>
                  <Grid>
                      <IconButton edge="start" href="https://www.facebook.com/Speaksy-103645015406771" rel="noopener noreferrer" target="_blank" className={classes.menuButton} color="inherit" >
                      <FacebookIcon style={{color: "#F36432", height:"3em", width:"3em"}}/>
                      </IconButton>
                      <IconButton edge="start" href="https://www.youtube.com/channel/UCBQy_q2QPXSjeCcFbn9DOhQ/channels" rel="noopener noreferrer" target="_blank" className={classes.menuButton} color="inherit" >
                      <YouTubeIcon style={{color: "#F36432",height:"3em", width:"3em"}}/>
                      </IconButton>
                      <IconButton edge="start" href="https://www.instagram.com/speaksyapp/" rel="noopener noreferrer" target="_blank" className={classes.menuButton} color="inherit" >
                      <InstagramIcon style={{color: "#F36432",height:"3em", width:"3em"}}/>
                      </IconButton>
                  </Grid>
                  </Paper>
                  </Grid>
          
              </Grid >

              <Grid container justifyContent="center">
                <Grid sm={12} md={4}  style={{margin:"1em"}}>
                  {/* <iframe width="500" height="718" src="https://www.instagram.com/p/CUInLQ3ppK5/embed" frameborder="0"></iframe> */}
                  <iframe width="450" height="500" src="https://www.instagram.com/p/CUInLQ3ppK5/embed" frameborder="0"></iframe>                                    
                </Grid>
                <Grid sm={12} md={4}  style={{margin:"1em"}}>
                  <div class="fb-page" data-href="https://www.facebook.com/NoSoftwareNoLife/" data-tabs="timeline" data-width="450" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NoSoftwareNoLife/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NoSoftwareNoLife/">Speaksy</a></blockquote></div>
                </Grid>                
              </Grid>
              <Grid container justifyContent="center" style={{marginBottom:20, flexDirection: "column"}}>
                <Typography variant="h6" style={{marginTop:20, color:"#6c6c6c"}} >
                    Sí crees que pueda ayudar a alguien, compártenos en Facebook      
                </Typography>
                <div class="fb-share-button" data-href="https://speaksy.feriadesoftware.cl/#/" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fspeaksy.feriadesoftware.cl%2F%23%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div>
              </Grid>
              </Grid>
          </header>
        </div>
    );
}
export default Home;
import React, { useState } from 'react';
// import React, { Component, useState } from 'react';
//import {Nav, NavDropdown } from 'react-bootstrap';
//import { LinkContainer } from 'react-router-bootstrap'
// import Box from '@material-ui/core/Box'
// import { Grid, Container } from '@material-ui/core';
import { AppBar, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
// import logo from "./Carita.svg";
// import FacebookIcon from '@material-ui/icons/Facebook';
import { Link, animateScroll as scroll } from "react-scroll";
// import logodigevo from "./Evolution naranjo.png";
import logoSpeaksy from "./logoS.svg";
import { HashRouter as Router, Route , Link as Lik} from "react-router-dom";
import Home from '../../Views/home';
import Equipo from '../../Views/equipo';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      width: "100%",
      ["@media (max-width:780px)"]: {
          display: "inline-block"
      }
    },
    bar:{
      display: "flex",
      alignItems: "center",
      padding: "0.1rem calc((100vw - 1000px)/2)",
      backgroundColor: "#F36432",
      zIndex: "10",
      ["@media (max-width:780px)"]: {
          flexDirection: "column",
      }
    },
    ele:{
      width: "100%",
      display: "flex",
      ["@media (max-width:780px)"]: {
        flexDirection: "column",
        alignItems: "start",
        zIndex: "999",
      },
    },
    disap:{
      ["@media (max-width:780px)"]: {
        display: "none"
      }
    },
    lmenu:{
      display: "none",
      color: "#fff",
      ["@media (max-width:780px)"]: {
        display: "block",
        position: "absolute",
        top: "0",
        right: "0",
        transform: "translate(-100%, 75%)",
        fontSize: "1.8rem",
        cursor: "pointer",
      },
    },
    menuItem: {
      textAlign: "left",
      textDecoration: "none",
      fontSize: "22px",
      cursor: "pointer",
      color: "#fff",
      flexGrow: 1,
      marginLeft: "0.5rem",
      ["@media (max-width:1100px)"]: {
        fontSize: "20px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "19px",
      },
      ["@media (max-width:780px)"]: {
          fontSize: "18px",
      }
    }
  }));

const scrollToTop = () => {
    scroll.scrollToTop();
};

function Navbar() {
    const classes = useStyles();

    const [showResults, setShowResults] = useState(false)
    const onClick = () => setShowResults(!showResults)

    const Results = () => (
      <div id="results">
        <div className={classes.ele}>
            {/* <Typography variant="h6" className={classes.title}> */}
              {/*News*/}
            {/* </Typography> */}
            <Button color="inherit" > 
              <Link
                className={classes.menuItem}                
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Inicio
              </Link>
            </Button>
            
            <Button color="inherit">
              <Link
                className={classes.menuItem}
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Funcionalidades
              </Link>
            </Button>
            <Button color="inherit">
              <Link
                className={classes.menuItem}
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Contacto
              </Link>
            </Button> 
            <Button color="inherit">
              <Lik
                className={classes.menuItem}
                activeClass="active"
                to={{pathname: "/equipo"}}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                ¿Quiénes somos?
              </Lik>
            </Button>       
            </div>
      </div>
    )

    return(
      <AppBar position="sticky">
        <Router>
        <Route
          exact
          path="/equipo"
          component={Equipo}/>
        </Router>

        <Toolbar className={classes.bar}>
            <Button>
                <img src={logoSpeaksy} alt="logo" width= "200px" height= "100px" onClick={scrollToTop}/>
            </Button>
            <MenuIcon onClick={onClick} className={classes.lmenu} />
            { showResults ? <Results /> : null }
            <div className={`${classes.ele} ${classes.disap}`}>
            <Typography variant="h6" className={classes.title}>
              {/*News*/}
            </Typography>
            <Button color="inherit" > 
              <Link
                className={classes.menuItem}                
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Inicio
              </Link>
            </Button>            
            <Button color="inherit">
              <Link
                className={classes.menuItem}
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Funcionalidades
              </Link>
            </Button>    
            <Button color="inherit">
              <Link
                className={classes.menuItem}
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Contacto
              </Link>
            </Button> 
            <Button color="inherit">
              <Lik
                className={classes.menuItem}
                activeClass="active"
                to={{pathname: "/equipo"}}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                ¿Quiénes somos?
              </Lik>
            </Button>       
            </div>
            
            {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <FacebookIcon />
            </IconButton>*/}
        </Toolbar>
        
      </AppBar>
    );
};

export default Navbar;
// import logo from './logo.svg';
import './App.css';
//import {  BrowserRouter as Router,  Route} from 'react-router-dom';
import Footer from './Components/Footer/footer'
import NavBar from './Components/Navbar/navbar'
import Grido from './Components/Grid';
// import { Link, animateScroll as scroll } from "react-scroll";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
// import { Grid, Paper, Avatar, TextField } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { IconButton } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import jean from "./Jean.jpg";
import franco from "./Franco.jpg";
import gabriel from "./Gabriel.jpg"
import bastian from "./Bastian.jpg"
import person from "./person.jpg";
import EmailIcon from '@material-ui/icons/Email';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Home from './Views/home';
import Equipo from './Views/equipo';
import {  HashRouter as Router,  Route} from 'react-router-dom';
import NotFound from './Views/notfound';
import { Switch } from 'react-router';
import ScrollToTop from './Components/ScrollToTop'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: 'auto'
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center", display: "flex" 
    },
  },
  avatar:{
    [theme.breakpoints.down('sm')]: {
      width:"5em", height:"5em"
    },

  },
  features:{
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

function App() {
  const classes = useStyles();
  return (
    <div className="App">
      <Router>
      {/* <header style={{marginTop:30}} className="seccion" id="section1"> */}
      <Switch>
      <Route
        exact
        path={["/home", "/"]}
        component={Home}/>
        <Route
        exact
        path='/equipo'
        component={Equipo}/>
        <Route component={NotFound} />
        </Switch>
        <ScrollToTop/>
      </Router>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
